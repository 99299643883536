// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-community-churches-index-js": () => import("./../../../src/pages/community/churches/index.js" /* webpackChunkName: "component---src-pages-community-churches-index-js" */),
  "component---src-pages-community-groups-index-js": () => import("./../../../src/pages/community/groups/index.js" /* webpackChunkName: "component---src-pages-community-groups-index-js" */),
  "component---src-pages-community-index-js": () => import("./../../../src/pages/community/index.js" /* webpackChunkName: "component---src-pages-community-index-js" */),
  "component---src-pages-community-riverlevels-js": () => import("./../../../src/pages/community/riverlevels.js" /* webpackChunkName: "component---src-pages-community-riverlevels-js" */),
  "component---src-pages-community-schools-index-js": () => import("./../../../src/pages/community/schools/index.js" /* webpackChunkName: "component---src-pages-community-schools-index-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-explore-index-js": () => import("./../../../src/pages/explore/index.js" /* webpackChunkName: "component---src-pages-explore-index-js" */),
  "component---src-pages-gallery-index-js": () => import("./../../../src/pages/gallery/index.js" /* webpackChunkName: "component---src-pages-gallery-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-visit-index-js": () => import("./../../../src/pages/visit/index.js" /* webpackChunkName: "component---src-pages-visit-index-js" */),
  "component---src-templates-business-category-template-index-js": () => import("./../../../src/templates/BusinessCategoryTemplate/index.js" /* webpackChunkName: "component---src-templates-business-category-template-index-js" */),
  "component---src-templates-business-index-template-index-js": () => import("./../../../src/templates/BusinessIndexTemplate/index.js" /* webpackChunkName: "component---src-templates-business-index-template-index-js" */),
  "component---src-templates-business-template-index-js": () => import("./../../../src/templates/BusinessTemplate/index.js" /* webpackChunkName: "component---src-templates-business-template-index-js" */),
  "component---src-templates-communities-template-index-js": () => import("./../../../src/templates/CommunitiesTemplate/index.js" /* webpackChunkName: "component---src-templates-communities-template-index-js" */),
  "component---src-templates-events-template-index-js": () => import("./../../../src/templates/EventsTemplate/index.js" /* webpackChunkName: "component---src-templates-events-template-index-js" */),
  "component---src-templates-news-template-index-js": () => import("./../../../src/templates/NewsTemplate/index.js" /* webpackChunkName: "component---src-templates-news-template-index-js" */),
  "component---src-templates-standard-page-template-index-js": () => import("./../../../src/templates/StandardPageTemplate/index.js" /* webpackChunkName: "component---src-templates-standard-page-template-index-js" */)
}

